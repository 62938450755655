import { render, staticRenderFns } from "./CandidatoConfig.vue?vue&type=template&id=567b48cf&scoped=true"
import script from "./CandidatoConfig.vue?vue&type=script&lang=js"
export * from "./CandidatoConfig.vue?vue&type=script&lang=js"
import style0 from "./CandidatoConfig.vue?vue&type=style&index=0&id=567b48cf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "567b48cf",
  null
  
)

export default component.exports
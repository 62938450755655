import {endpoints} from './endpoints'
import request from '../helpers/request'

export function publicidadList(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.publicidadList,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function sendPublicidad(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.sendAndSavePublicidad,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getPublicidadById(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.publicidadById,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

////////////////////////////////////
		// Publicidad
/////////////////////////////////////////////////

		//crear Publicidad
		export function createAdvertisement(data) {
			return new Promise((resolve, reject) => {
				request({
			url: endpoints.createAdvertisement,
			method: 'post',
			data
		})
		.then(async function (response) {
			resolve(response)
		}).catch(function (error) {
			reject(error)
		})
	})
}
//Get Tipos de Anuncio
export function getAdvertisementTypes() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getAdvertisementTypes,
			method: 'get',
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}
	//Get Publicidades
export function getCompanyAdvertisement(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyAdvertisement,
			method: 'get',
			params:data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}
	//Get Anuncios por Publicidad
export function getAdvertisementDetail(params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getAdvertisementDetail,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}
	//Crear Nuevo Anuncio para una Publicidad
export function createAdvertisementConfig(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createAdvertisementConfig,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}
	//Actualizar Publicidad
export function updateAdvertisement(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateAdvertisement,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}
	//Actualizar Anuncio
export function updateAdvertisementConfig(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateAdvertisementConfig,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}
	//Eliminar Publicidad
	export function deleteAdvertisement(data) {
		return new Promise((resolve, reject) => {
			request({
				url: endpoints.deleteAdvertisement,
				method: 'delete',
				data
			})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}
	//Eliminar Anuncio
	export function deleteAdvertisementConfig(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteAdvertisementConfig,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}
	//Crear Step
	export function createAdvertisementStepConfig(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createAdvertisementStepConfig,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}
		//Eliminar Step
		export function deleteAdvertisementStepConfig(data) {
			return new Promise((resolve, reject) => {
				request({
					url: endpoints.deleteAdvertisementStepConfig,
					method: 'delete',
					data
				})
					.then(async function (response) {
						resolve(response)
					}).catch(function (error) {
						reject(error)
					})
				})
			}

			export function updateAdvertisementStepConfig(data) {
				return new Promise((resolve, reject) => {
					request({
						url: endpoints.updateAdvertisementStepConfig,
						method: 'post',
						data
					})
						.then(async function (response) {
							resolve(response)
						}).catch(function (error) {
							reject(error)
						})
					})
				}

				//Enviar notificación
			export function sendAdvertisementNotification(data) {
				return new Promise((resolve, reject) => {
					request({
						url: endpoints.sendAdvertisementNotification,
						method: 'post',
						data
					})
						.then(async function (response) {
							resolve(response)
						}).catch(function (error) {
							reject(error)
						})
					})
				}

				//Acciones
				export function getAdvertisementActions(data) {
					return new Promise((resolve, reject) => {
						request({
							url: endpoints.getAdvertisementActions,
							method: 'get',
							params: data
						})
							.then(async function (response) {
								resolve(response)
							}).catch(function (error) {
								reject(error)
							})
						})
					}

				export function sendAdvertisementNotificationToUsers(data) {
					return new Promise((resolve, reject) => {
						request({
							url: endpoints.sendAdvertisementNotificationToUsers,
							method: 'post',
							data
						})
							.then(async function (response) {
								resolve(response)
							}).catch(function (error) {
								reject(error)
							})
						})
					}



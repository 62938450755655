<template>
  <div style="margin-top: 30px">
    <div>
      <div style="max-width: 900px; margin-top: 16px">
        <!-- NOTICIAS LOCALES -->

          <div style="display: flex; justify-content: space-between">
            <div style="display: flex; gap: 8px">
              <CSelect v-model="selectSponsorId" :options="sponsorsTable" />
 <!--              <button class="saveBtn" @click="getNews">Seleccionar</button> -->
            </div>
            <button class="saveBtn" @click="() => (createModal = !createModal)">
              Crear nueva
            </button>
          </div>

          <!--     Tabla Noticias -->
          <CDataTable
            :fields="fields"
            :items="news"
            hover
            sorter
            style="margin-top: 24px"
            :items-per-page="limit"
          >
            <template #action="{ item }">
              <td class="py-2">
                <CButton
                  class="mr-2"
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  @click="setCurrentItem(item, 'edit')"
                >
                  <img style="width: 1rem" src="../../assets/icons/svg/edit.svg" alt="" />
                </CButton>
                <CButton
                  color="red"
                  variant="outline"
                  square
                  size="sm"
                  @click="setCurrentItem(item, 'delete')"
                >
                  <img
                    style="width: 1rem"
                    src="../../assets/icons/svg/delete.svg"
                    alt=""
                  />
                </CButton>
              </td>
            </template>
          </CDataTable>
          <CPagination :activePage.sync="start" :pages="totalPages" />

          <!--     Modal crear -->
          <CModal
            style="width: 40% !important"
            class="modal-sm"
            title="Crear Noticia"
            color="primary"
            :show.sync="createModal"
            v-if="createModal"
            size="sm"
          >
            <CRow>
              <CCol col="12" md="6">
                <CSelect
                  v-model="newNew.sponsor_id"
                  label="Sponsor"
                  :options="sponsors"
                />
              </CCol>
              <CCol col="12" md="6"> </CCol>

              <CCol col="12" md="6">
                <CInput label="Título" v-model="newNew.title" />
              </CCol>
              <CCol col="12" md="6">
                <CInput label="URL" v-model="newNew.url" />
              </CCol>

              <CCol sm="12">
                <CRow>
                  <CCol sm="6" md="4">
                    <label class="label-img">Imagen: </label>
                    <div
                      class="input-group file-input-group"
                      data-controller="file-input2"
                    >
                      <input
                        :value="fileNameImageCreate"
                        style="background-color: #fff"
                        class="form-control"
                        type="text"
                        placeholder="Selecionar archivo"
                        readonly
                        data-target="file-input2.value"
                      />
                      <input
                        @change="onChangeImageCreate"
                        type="file"
                        class="form-control"
                        id="customFile2"
                        data-target="file-input2.input"
                        data-action="file-input2#display"
                      />
                      <div class="input-group-append">
                        <label
                          style="background-color: #fff"
                          class="btn btn-secondary mb-0"
                          for="customFile2"
                          ><img
                            style="width: 18px"
                            src="../../assets/icons/svg/clip.svg"
                            alt=""
                        /></label>
                      </div>
                    </div>
                    <img
                      v-if="previewImageCreate"
                      class="img-preview"
                      :src="previewImageCreate"
                      alt="preview"
                    />
                  </CCol>
                  <CCol sm="6" md="4">
                    <label class="label-img">Fecha: </label>
                    <datetime
                      width="100%"
                      class="my-2 datepickers__datepicker"
                      type="datetime"
                      v-model="newNew.date"
                      :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
                    ></datetime>
                  </CCol>
                  <CCol sm="6" md="4">
                    <div>
                      <label class="label-img">Notificaciones: </label>
                    </div>
                    <CSwitch
                      variant="opposite"
                      color="dark"
                      :checked.sync="newNew.notification"
                      shape="pill"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <button
                style="margin-left: 12px; margin-top: 8px"
                class="saveBtn"
                @click="
                  wysiwygNew = true;
                  newNew.body = '';
                "
                v-if="!wysiwygNew"
              >
                Editor de texto
              </button>
              <button
                style="margin-left: 12px; margin-top: 8px"
                class="saveBtn"
                @click="
                  wysiwygNew = false;
                  newNew.body = '';
                "
                v-else
              >
                Insertar HTMl
              </button>
              <CCol sm="12">
                <div v-if="wysiwygNew">
                  <wysiwyg v-model="newNew.body" />
                </div>
                <div v-else>
                  <textarea
                    style="margin-top: 8px; margin-left: 2px; width: 95%; height: 150px"
                    v-model="newNew.body"
                  ></textarea>
                </div>
              </CCol>
            </CRow>
            <div slot="footer" class="w-100">
              <CButton
                class="mr-2"
                color="primary"
                square
                :disabled="
                  !newNew.title ||
                  !newNew.url ||
                  !newNew.image ||
                  !newNew.date ||
                  !newNew.body
                "
                size="sm"
                @click="createNew()"
              >
                Guardar
              </CButton>
              <CButton
                color="red"
                square
                size="sm"
                @click="() => (createModal = !createModal)"
              >
                Cancelar
              </CButton>
            </div>
          </CModal>

          <!--     Modal Eliminar -->
          <CModal
            title="Eliminar Noticia"
            color="primary"
            :show.sync="deleteModal"
            size="sm"
          >
            ¿Seguro que quieres eliminar la noticia
            <span style="font-weight: 800">{{ currentNew.title }}</span
            >?
            <div slot="footer" class="w-100">
              <CButton
                class="mr-2"
                color="red"
                variant="outline"
                square
                @click="deleteNew()"
              >
                Eliminar
              </CButton>
              <CButton
                color="secondary"
                variant="outline"
                square
                @click="() => (deleteModal = !deleteModal)"
              >
                Cancelar
              </CButton>
            </div>
          </CModal>

          <!--     Modal editar -->
          <CModal
            class="modal-sm"
            title="Editar noticia"
            color="primary"
            :show.sync="editModal"
            size="sm"
          >
            <CRow>
              <CCol col="12" md="6">
                <CSelect
                  v-model="currentNew.sponsor_id"
                  label="Sponsor"
                  :options="sponsors"
                />
              </CCol>
              <CCol col="12" md="6"> </CCol>
              <CCol sm="12">
                <CInput label="Título" v-model="currentNew.title" />
              </CCol>
              <CCol sm="12">
                <CInput label="URL" v-model="currentNew.url" />
              </CCol>
              <CCol sm="12">
                <CRow>
                  <CCol sm="6" md="4">
                    <label class="label-img">Imagen: </label>
                    <div
                      class="input-group file-input-group"
                      data-controller="file-input5"
                    >
                      <input
                        :value="fileNameImageUpdate"
                        style="background-color: #fff"
                        class="form-control"
                        type="text"
                        placeholder="Selecionar archivo"
                        readonly
                        data-target="file-input5.value"
                      />
                      <input
                        @change="onChangeImageUpdate"
                        type="file"
                        class="form-control"
                        id="customFile5"
                        data-target="file-input5.input"
                        data-action="file-input5#display"
                      />
                      <div class="input-group-append">
                        <label
                          style="background-color: #fff"
                          class="btn btn-secondary mb-0"
                          for="customFile5"
                          ><img
                            style="width: 18px"
                            src="../../assets/icons/svg/clip.svg"
                            alt=""
                        /></label>
                      </div>
                    </div>
                    <img
                      v-if="previewImageUpdate"
                      class="img-preview"
                      :src="previewImageUpdate"
                      alt="preview"
                    />
                  </CCol>
                  <CCol sm="6" md="4">
                    <label class="label-img">Fecha: </label>
                    <datetime
                      width="100%"
                      class="my-2 datepickers__datepicker"
                      type="datetime"
                      v-model="currentNew.date"
                      :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
                    ></datetime>
                  </CCol>
                  <!--                 <CCol sm="6" md="4">
                  <div>
                    <label class="label-img">Notificaciones: </label>
                  </div>
                  <CSwitch
                    variant="opposite"
                    color="dark"
                    :checked.sync="currentNew.notifications"
                    shape="pill"
                  />
                </CCol> -->
                </CRow>
                <button
                  style="margin-left: 4px; margin-top: 8px"
                  class="saveBtn"
                  @click="
                    wysiwygCurrent = true;
                    currentNew.body = '';
                  "
                  v-if="!wysiwygCurrent"
                >
                  Editor de texto
                </button>
                <button
                  style="margin-left: 4px; margin-top: 8px"
                  class="saveBtn"
                  @click="
                    wysiwygCurrent = false;
                    currentNew.body = '';
                  "
                  v-else
                >
                  Insertar HTMl
                </button>
                <CCol sm="12">
                  <div v-if="wysiwygCurrent">
                    <wysiwyg v-model="currentNew.body" />
                  </div>
                  <div v-else>
                    <textarea
                      style="margin-top: 8px; width: 95%; height: 150px"
                      v-model="currentNew.body"
                    ></textarea>
                  </div>
                </CCol>
              </CCol>
            </CRow>
            <div slot="footer" class="w-100">
              <CButton
                class="mr-2"
                color="primary"
                square
                size="sm"
                :disabled="
                  !currentNew.title ||
                  !currentNew.url ||
                  !currentNew.image ||
                  !currentNew.date ||
                  !currentNew.body
                "
                @click="updateNew()"
              >
                Guardar
              </CButton>
              <CButton
                color="red"
                square
                size="sm"
                @click="() => (editModal = !editModal)"
              >
                Cancelar
              </CButton>
            </div>
          </CModal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSponsorNews,
  deleteNews,
  createNews,
  updateNews,
  getLocalNewsConfigOptions,
  changeNewsConfigOptions,
  changeNewsConfigExternalOptions,
} from "../../api/noticias.js";
import { getAdminSponsors } from "../../api/sponsor.js";


import { CDataTable, CModal, CFormCheck } from "@coreui/vue";
import moment from "moment";

export default {
  components: {
    CDataTable,
    CModal,
  },
  data() {
    return {
      togglePass: false,
      currentNew: {},
      newNew: {
        title: "",
        date: "",
        url: "",
        image: "",
        body: "",
        notification: true,
        sponsor_id: null,
      },
      newsConfig: [],
      //modales
      createModal: false,
      editModal: false,
      deleteModal: false,
      //tabla
      start: 1,
      limit: 10,
      totalNews: 0,
      news: [],
      fields: [
        {
          key: "title",
          label: "Título",
          _props: { scope: "col" },
        },
        {
          key: "action",
          label: "Acción",
          _props: { scope: "col" },
        },
      ],
      //Imagenes
      fileNameImageCreate: "",
      previewImageCreate: "",
      fileNameImageUpdate: "",
      previewImageUpdate: "",
      fileNameMain_icon: "",
      previewMain_icon: "",

      //crud configuracion general
      company_news_app_data: {},
      company_news_config: {},
      login_activo: false,

      alertSuccess1: false,
      alertError1: false,
      alertSuccess2: false,
      alertError2: false,
      sponsors: [],
      sponsorsTable: [],
      selectSponsorId: null,

      wysiwygNew: true,
      wysiwygCurrent: true,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalNews / this.limit);
    },
    externalFormDisabled() {
      if (
        (this.login_activo &&
          (!this.company_news_config.login_username ||
            !this.company_news_config.login_password ||
            !this.company_news_config.login_url ||
            !this.company_news_config.login_request_type)) ||
        !this.company_news_config.request_type ||
        !this.company_news_config.news_request_url
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getNews() {
      const data = {
        start: this.start,
        limit: this.limit,
        sponsor_id: this.selectSponsorId,
      };
      getSponsorNews(data).then((res) => {
        this.news = res.news.map((noticia) => {
          noticia.date = moment(noticia.date).utc()._i;
          return noticia;
        });
        this.totalNews = res.total;
      });
    },
    setCurrentItem(item, modal) {
      this.currentNew = { ...item };

      this.currentNew.date = moment(item.date).format();

      //qué modal se abre
      modal == "edit"
        ? (this.editModal = !this.editModal)
        : (this.deleteModal = !this.deleteModal);

      //preview imágenes
      this.previewImageUpdate = this.currentNew.image ? this.currentNew.image : "";
    },
    onChangeImageCreate(e) {
      this.newNew.image = e.target.files["0"];
      this.previewImageCreate = URL.createObjectURL(e.target.files["0"]);
      this.fileNameImageCreate = e.target.files["0"].name;
    },
    onChangeImageUpdate(e) {
      this.currentNew.image = e.target.files["0"];
      this.previewImageUpdate = URL.createObjectURL(e.target.files["0"]);
      this.fileNameImageUpdate = e.target.files["0"].name;
    },
    onChangeMain_icon(e) {
      this.company_news_app_data.main_icon = e.target.files["0"];
      this.previewMain_icon = URL.createObjectURL(e.target.files["0"]);
      this.fileNameMain_icon = e.target.files["0"].name;
    },
    deleteNew() {
      deleteNews(this.currentNew.id).then((res) => {
        this.deleteModal = !this.deleteModal;
        this.getNews();
      });
    },
    createNew() {
      const data = new FormData();
      const obj = { ...this.newNew };
      obj.notification = this.newNew.notification == true ? 1 : 0;
      obj.date = moment(this.newNew.date).format("YYYY-MM-DD HH:mm:ss");
      for (const property in obj) {
        data.append(property, obj[property]);
      }
      createNews(data).then((res) => {
        this.createModal = !this.createModal;
        this.getNews();
        this.newNew = {
          title: "",
          date: "",
          url: "",
          image: "",
          body: "",
          notification: true,
          sponsor_id: this.selectSponsorId,
        };
        this.previewImageCreate = "";
        this.fileNameImageCreate = "";
      });
    },
    updateNew() {
      const data = new FormData();
      const obj = {...this.currentNew}
      obj.date = moment(this.currentNew.date).format("YYYY-MM-DD HH:mm:ss");

      const originalObj = this.news.filter((n) => n.id == obj.id)[0];
      for (const property in obj) {
        if (originalObj[property] !== obj[property]) {
          data.append(property, obj[property]);
        }
      }
      /* data.append("_method", "PATCH"); */
      data.append("news_id", obj.id);

      updateNews(data).then((res) => {
        this.editModal = !this.editModal;
        this.getNews();
      });
    },
    getLocalNewsConfig() {
      getLocalNewsConfigOptions().then((res) => {
        this.company_news_app_data = res.company_news_app_data;
        this.original_company_news_app_data = res.company_news_app_data
          ? res.company_news_app_data
          : {};
        this.company_news_config = res.company_news_config ? res.company_news_config : {};

        this.previewMain_icon = res.company_news_app_data.main_icon;

        //estado inicial login_activo
        this.login_activo =
          !res.company_news_config.login_username &&
          !res.company_news_config.login_password &&
          !res.company_news_config.login_url &&
          !res.company_news_config.login_request_type
            ? false
            : true;
      });
    },

    saveConfigOptions() {
      const obj = { ...this.company_news_app_data };
      obj.active = true ? 1 : 0;
      obj.notification = true ? 1 : 0;
      const data = new FormData();

      if (!obj.active) {
        data.append("active", obj.active);
      } else {
        for (const property in obj) {
          data.append(property, obj[property]);
        }
        if (typeof obj.main_icon == "string") {
          data.delete("main_icon");
        }
      }

      data.append("_method", "PATCH");
      changeNewsConfigOptions(data)
        .then((res) => {
          this.alertSuccess1 = true;
          setTimeout(() => {
            this.alertSuccess1 = false;
          }, 1000);
        })
        .catch((e) => {
          this.alertError1 = true;
          setTimeout(() => {
            this.alertError1 = false;
          }, 1000);
        });
    },
    saveExternalOptions() {
      const data = { ...this.company_news_config };
      if (!this.login_activo) {
        delete data.login_username;
        delete data.login_password;
        delete data.login_url;
        delete data.login_request_type;
      }

      changeNewsConfigExternalOptions(data)
        .then((res) => {
          this.alertSuccess2 = true;
          setTimeout(() => {
            this.alertSuccess2 = false;
          }, 1000);
        })
        .catch((e) => {
          this.alertError2 = true;
          setTimeout(() => {
            this.alertError2 = false;
          }, 1000);
        });
    },
    updateActiveNewsConfig(active) {
      const data = new FormData();
      data.append("active", active);
      data.append("_method", "PATCH");
      changeNewsConfigOptions(data).then((res) => {
        console.log(res);
      });
    },
    getSponsors() {
      getAdminSponsors().then((res) => {
        this.sponsors = res.sponsors.map((sponsor) => {
          return { value: sponsor.id, label: sponsor.name };
        });
        this.sponsorsTable = res.sponsors.map((sponsor) => {
          return { value: sponsor.id, label: sponsor.name };
        });
        this.sponsorsTable.push({ value: null, label: "Todos" });

        this.newNew.sponsor_id = res?.sponsors[0]?.id
      });
    },
  },
  watch: {
    start() {
      this.getNews();
    },
    //active config noticias
    "company_news_app_data.active": function (active) {
      const activeNumber = active == true ? 1 : 0;
      this.updateActiveNewsConfig(activeNumber);
    },
    //active noticias externas --> seclect local o externas
    "company_news_config.active": function (active) {
      const activeNumber = active == true ? 1 : 0;
      changeNewsConfigExternalOptions({ active: activeNumber });
    },
    selectSponsorId(value) {
      this.newNew.sponsor_id = value
      setTimeout(() => {
        this.getNews();
      }, 500);
    },
  },
  mounted() {
    this.getNews();
    this.getSponsors();
    /*     this.getLocalNewsConfig(); */
  },
};
</script>

<style lang="scss" scoped>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
@import "../../assets/scss/style.scss";
.file-input-group {
  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

.color-input {
  display: flex;
  align-items: center;
  gap: 20px;
}
.color-input label {
  margin-bottom: 0;
}
.img-preview {
  margin-top: 8px;
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.label-img {
  margin-right: 5px;
}
.saveBtn {
  background: transparent
    linear-gradient(180deg, #0064ED 0%, #90b2e2 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 7px;
  opacity: 1;
  top: 410px;
  left: 339px;
  width: 123px;
  height: 37px;
  font: normal normal normal 14px/18px Montserrat;
  color: #fff;
  border: none;
}

.saveBtn:disabled,
.saveBtn[disabled] {
  opacity: 0.6;
}

.form-group {
  margin-bottom: 8px;
  margin-top: 0 !important;
}
</style>

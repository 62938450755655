<template>
	<nav aria-label="pagination-wrapper">
	  <ul class="pagination justify-content-center">
	    <li class="page-item">
	      <a class="page-link" style="cursor: pointer;" @click="changePage(1, perPage)" aria-label="Previous">
	        <span aria-hidden="true">&laquo;</span>
	        <span class="sr-only">Anterior</span>
	      </a>
	    </li>
	    <li v-for="index in Math.ceil(total/perPage)" class="page-item"><div class="page-link" style="cursor: pointer;" @click="changePage(index, perPage)">{{index}}</div></li>
	    <li class="page-item">
	      <a class="page-link" style="cursor: pointer;" @click="changePage(Math.ceil(total/perPage), perPage)" aria-label="Next">
	        <span aria-hidden="true">&raquo;</span>
	        <span class="sr-only">Siguiente</span>
	      </a>
	    </li>
	  </ul>
	</nav>
</template>

<script>
export default {
  name: 'Pagination',
  props: ['data', 'callback'],
  data: () => {
    return {
    	page: 1,
    	total: null,
    	perPage: 10
    }
  },
  watch: {
  	data: function(paginationData){
  		this.page = paginationData.page
  		this.perPage = paginationData.perPage
  		this.total = paginationData.total
  	}
  },
  mounted(){
  },
  methods: {
  	changePage(page, perPage){
  		console.log(page)
  		this.callback(page, perPage)
  	}
  }
}
</script>